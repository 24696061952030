<template>
  <el-form-item :label="itemData.label" v-bind="[itemData]" :prop="itemKey">
    <!-- 默认text -->
    <el-input :type="itemData.type"  v-model="itemVal" v-bind="[itemData]" @input="input" @change="change">
      <i v-if="itemData.icon" :slot="itemData.iconPosition||'prefix'" class="el-input__icon" :class="itemData.icon"></i>
    </el-input>

    <i v-if="itemData.iconBtn" class="iconBtn" :class="itemData.iconBtn" @click="handleBtn"></i>
  </el-form-item>
</template>

<script>

export default {
  props: {

    // 表单项配置数据
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单键值
    itemKey: String,

    // 父组件双向绑定的model值
    value: {
      type: [String, Number]
    },

    // 当前所在表单组配置项,用于事件函数的参数
    groupData: Object,

    // 表单配置项,用于事件函数的参数
    formData: Array,

    // 表单数据,用于事件函数的参数
    form: Object
  },
  watch: {
    value() {
      this.itemVal = this.value
    }
  },
  model: {
    // 与父组件进行双向绑定
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      itemVal: this.value
    }
  },
  methods: {
    valuechange() {
      console.log('检测到变化')
    },
    // input方法触发父组件同步更新数据
    input(e) {
      this.$emit('input', this.itemVal)
    },
    change(val) {
      if (typeof (this.itemData.changeEvent) === 'function') {
        this.itemData.changeEvent(val, this.form, this.groupData, this.formData, this.$parent.$parent.$parent.$parent.$parent.$parent.$parent)
      }
    },
    handleBtn(val) {
      if (typeof (this.itemData.buttonEvent) === 'function') {
        this.itemData.buttonEvent(val, this.form, this.groupData, this.formData, this.$parent.$parent.$parent.$parent.$parent.$parent.$parent)
      }
    }
  }
}
</script>
<style scoped>
.iconBtn {
  margin-left: 10px;
  font-size: 20px;
}
</style>
